// Created another style sheet for the message box as I didn't want to clutter the main scss index
// Importing this to index.scss so that there is just one compile
// Alternatively - we could get rid of node sass and get Dart Sass to make this a little easier, but I didn't want to screw with your styling and start over
// Will do my best to label things so its easy to find when referenced.



.home {
    display: flex;
    align-items: center;
    justify-content: center;
}

.homecontainer {
    display: flex;
    height: 760px;
    width: 85%;
    max-width: 1400px;
    overflow:hidden;
    margin-right: .5em;
    border: solid 2px;
    border-radius: 30px;
    border-color: rgba(243, 238, 238, 0.741);
    margin-bottom: 4em;
    min-width: 370px;
}

.sidebarmessage {
    flex: 1;
	padding: .4em;
    border-right: solid 2px ;
    border-right-color: rgba(243, 238, 238, 0.741);
    min-width: 350px;
}

.chatmessage {
    flex: 2;

    .chatinfo{
        height: 76.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        padding-left: 5px;
        padding-right: 5px;
        gap: 5px;
        color: antiquewhite;
        border-bottom: solid 2px ;
        border-bottom-color: rgba(243, 238, 238, 0.741);
        font-weight: bold ;
    }

    .chaticons{
        display: flex;
        gap: 10px;

        a{cursor: pointer;
        margin-right: 10px;
        }    

    }
}


.chatsmessage {
    border-bottom: 1px antiquewhite;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: rgba(0,0,0,0.3);

}

.navuser {
    display:flex;
    gap: 10px;
}

.navmessage {
    display: flex;
    align-items: center;
    height: 70px;
    padding: .4em;
    padding-right: 5px;
    padding-left: 5px;
    justify-content: space-between;
    font-weight: bold;
    color: antiquewhite;
    border-bottom: solid 2px ;
    border-bottom-color: rgba(243, 238, 238, 0.741);
    
}

.pfpmessagebox {
    background-color: antiquewhite;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    object-fit: cover;
}

.navsearch {
    border-bottom: 1px solid gray;
}

.navsearchcontainer {
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: antiquewhite;
}

.navsearchbutton {
    cursor: pointer;
    display: flex;
    padding: 5px;
    padding-bottom: 1px;
    gap: 5px;
    align-items: center;
    border-bottom: 1px solid gray;

    &:hover {
        background-color:  rgba(250, 250, 250, 0.2);
    } 
}

.navsearchbutton-alternate {
    cursor: pointer;
    display: flex;
    padding: 5px;
    padding-bottom: 1px;
    gap: 5px;
    align-items: center;
    background-color: #919191;

    &:hover {
        background-color:  rgba(255, 255, 255, 0.788);
    } 
}

.navsearchbutton-asker-selected {
    align-items: center;
    background-color: #ffffffe8;
    cursor: not-allowed;
    display: flex;
    gap: 5px;
    padding: 5px;
    padding-bottom: 1px;
}

.navsearchform {
    border:none;
    color: antiquewhite;
    outline: none;
    border-radius: 15px;
    background-color: rgba(0,0,0,0.3);
    
}


.navuserchat {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
}


.navuserchatinfo {
   padding-top: 4px;
}

.currentUsernameChatColor {
    color: #f3df92;
}

.otherUsernameChatColor {
    color: #ffffff;
}

.messageboxchat {
    background-color: rgba(250, 250, 250, 0.2);;
    padding: 10px;
    height: calc(100% - 170px);
    overflow: scroll;
    overflow-x: hidden;

    .message{
        display: flex;
        gap: 10px;
        margin-bottom: 20px;

        .message1{
            display: flex;
            flex-direction: column;
            align-items: center;
            color: rgb(153, 153, 153);
            font-weight: 350;
            text-wrap:balance;
            width: 80px;
        }
        .message2{
            max-width: 80%;
            display: flex;
            flex-direction:column;
            gap: 10px;

            p{
                background-color: rgba(0, 0, 0, 0.3);
                padding: 10px 20px;
                border-radius: 0px 10px 10px 10px;
                max-width: max-content;
            }
        }

        &.ownerchat{
            flex-direction:row-reverse;

            .message2{
                align-items: flex-end;
                p{
                background-color: #7e98ee;
                color: white; 
                border-radius: 10px 0px 10px 10px;
                max-width: max-content;
                }
            }
        }

    }

}

.messageinputs{
    height: 100px;
    background-color: white;
    // padding-right: 25px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-top: 10px;
    padding-right: inherit;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    textarea{
        width: 100%;
        height: 80px;
        border: none;
        outline: none;
        color:  rgba(4, 4, 4, 0.782);
        font-size: 16px;
        padding-right: 100px;
        padding-left: 30px;
        padding-top: 12px;
        padding-bottom: 10px;

        &::placeholder{
            color: lightgray;
        }
    }
}

.nav-search-users-button.btn {
    background-color: #3735350d;

    &:hover {
        background-color:  rgba(255, 255, 255, 0.425);
    } 
}